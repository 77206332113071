import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import GroupService from '@/services/group.service'
import CountryService from '@/services/country.service'
import getEnv from '@/util/env'
import moment from 'moment'
import Contacts from '../Contacts/ContactsOfGroups.vue'
import DateRange from '@/components/DateRange/DateRange.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import EventBus from '@/util/EventBus'

export default {
  name: 'ContactsGroups',
  components: { HeaderTopDashboard, StatsTitle, DateRange, Contacts },
  data: function () {
    return {
      dataCountry: [],
      dataCountryKeys: [],
      countrySelected: '',
      dataStoreUrls: {},
      show: false,
      globalControl: new GlobalControl(),
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      selected: [],
      totalItems: 0,
      headers: [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: this.$t('Nombre'), align: 'start', value: 'name' },
        { text: this.$t('Contactos'), align: 'start', value: 'contacts_count' },
        { text: this.$t('Moviles'), align: 'start', value: 'contacts_with_phone_count' },
        { text: 'Emails', align: 'start', value: 'contacts_with_email_count' },
        { text: this.$t('Fijos'), align: 'start', value: 'contacts_with_landline_count' },
        { text: this.$t('Fecha alta'), align: 'start', value: 'date' },
        { text: this.$t('Última campaña'), align: 'start', value: 'lastCampaign' },
        { text: this.$t('Envíos'), align: 'start', value: 'processedSendings' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      data: [],
      ready: true,
      removeGroupDialog: false,
      removeGroupOption: '',
      actionItem: null,
      duplicateDialog: false,
      updateName: '',
      renameDialog: false,
      contactsDialog: false,
      debouncedInput: undefined,
      timeout: undefined,
      startDate: undefined,
      endDate: undefined,
    }
  },
  methods: {
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.getDataFromApi()
    },
    createCampaign (item, channel, option) {
      const that = this
      GroupService.getContacts({ groupId: item.id, channel: channel, option: option }).then(function (contactsInfo) {
          localStorage.setItem('contact-phones-new-campaign', JSON.stringify(contactsInfo))
          channel = channel === 'voice-interactive' ? 'voice' : channel
          channel = channel === 'landing' ? 'sms' : channel
          that.$router.push('/campaign/' + channel + '/create' + ((channel === 'sms' || channel === 'mailing') ? '?type=basic' : ''))
      })
    },

    showContactsByGroup (item) {
      this.actionItem = item
      this.contactsDialog = true
    },

    prepareRename (item) {
      this.actionItem = item
      this.updateName = '' + item.name
      this.renameDialog = true
    },

    rename () {
      GroupService.rename({ groupId: this.actionItem.id, name: this.updateName })
      .then(
        () => {
          this.renameDialog = false
          this.getDataFromApi()
        })
    },

    openDuplicateDialog (item) {
      this.updateName = ''
      this.duplicateDialog = true
      this.actionItem = item
    },

    duplicateGroup () {
      GroupService.duplicate({ groupId: this.actionItem.id, name: this.updateName })
      .then(
        () => {
          this.duplicateDialog = false
          this.getDataFromApi()
        })
    },
    prepareRemove (item) {
      this.actionItem = item
      this.removeGroupDialog = true
      this.removeGroupOption = ''
    },

    removeGroup () {
      GroupService.removeGroup({ id: this.actionItem.id, option: this.removeGroupOption })
      .then(
        (response) => {
          this.removeGroupDialog = false
          this.getDataFromApi()
        }).catch((error, s) => {
          GroupService.errorResponse(
          error.response.status,
          this.$t('No se puede eliminar') + '.' + this.$t('Tiene algún envío pendiente de finalizar'),
          this.$t(error.response.data.error),
        )
        })
    },

    exportGroup (item) {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('backend_endpoint') + 'api/group/export-group/' + item.id + '?token=' + user.token
      window.location.href = url
    },

    getLastCampaignLink (item) {
      switch (item.sendings[0].channel_type_id) {
        case 1:
          return '/stats/sms/campaign/' + item.sendings[0].campaign_id
        case 2:
          return '/stats/mailing/campaign/' + item.sendings[0].campaign_id
        case 3:
          return '/stats/voice/campaign/' + item.sendings[0].campaign_id
      }
    },

    selectCountry () {
      this.editedItem.country_iso = this.dataCountryKeys[this.dataCountry.indexOf(this.countrySelected)]
    },
    getCoutries () {
      CountryService.getCountries()
        .then(
          (response) => {
            this.dataCountry = Object.values(JSON.parse(JSON.stringify(response)))
            this.dataCountryKeys = Object.keys(JSON.parse(JSON.stringify(response)))
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    dateFormat (date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      this.content = []
      this.loading = true
      GroupService.getGroupsData(params)
        .then(
          (response) => {
            this.data = response.data
            this.itemsPerPage = parseInt(response.per_page)
            this.page = parseInt(response.current_page)
            this.totalItems = parseInt(response.total)
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? this.$t('Nuevo contacto') : this.$t('Editar contacto')
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getDataFromApi()
      },
      deep: true,
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created () {
  },
  mounted () {
    this.startDate = this.globalControl.initDate
    this.endDate = this.globalControl.endDate
    this.getCoutries()
  },
}
